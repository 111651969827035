import React from 'react'
import CaseStudiesSidebar from './CaseStudiesSidebar'
import details1 from '../../../assets/images/projects/projects-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about"/>
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Dezvoltare de produs</span>
                            <h3>Compania</h3>
                            <p>Una dintre cele mai mari companii aviatice din Europa ne-a solicitat realizarea unui 
                                proiect eDoc, un sistem de gestionare a documentelor provenite de la furnizorul lor 
                                de piese și componente aviatice.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="image">
                                        <img src={project2} alt="about"/>
                                    </div>
                                </div>
                                <h3>Provocarea</h3>
                                <p>Așa cum procedăm întotdeauna când ne aflăm la început de drum cu un client, am analizat
                                     cerințele acestuia, am creat un caiet de sarcini și, în urma discuțiilor am ajuns la
                                      concluzia că trebuie să creăm un sistem complex care nu doar că va stoca în format 
                                      digital toate documentele, dar le va și gestiona și organiza, în funcție de mai multe
                                        tipuri de atribute.</p>
                                <div className="col-lg-12 col-md-12">
                                    <div className="content">
                                        <h3>Soluția</h3>
                                        <p>Practic am creat de la 0 un sistem eDoc foarte bine organizat în mai multe categorii
                                             și sub-categorii de documente și manuale de utilizare folosite de mecanicii și
                                             piloții de avioane. Întregul sistem este o aplicație web Java care rulează pe un
                                             server Tomcat. Documentele au fost trimise în format XML sau SGML, procesate și
                                             transformate în mai multe formate și vizualizate cu XSLT. Fiecare tip de document
                                             dispune de propriile atribute, care pot fi gestionate zona de administrare a eDoc.</p>
                                             <p>Unul dintre cele mai importante module din eDoc este AVS, care permite crearea și
                                             gestionarea fișierelor. Zona de administrare a eDoc este foarte complexă și permite
                                             diverse moduri de administrare a documentelor. Sistemul are, de asemenea, un motor
                                             de căutare foarte puternic pentru documente (stocarea se află într-o bază de date
                                             Oracle 11g, iar căutarea textului integral se face cu Lucene). </p>
                                        <p>Proiectul a fost început acum cinci ani, pus pe pauză în perioada panemiei și reluat
                                             ulterior. </p>
                                             <p>Din cadrul companiei Vertical Digital au fost alocați proiectului 5 specialiști:
                                                 2 dezvoltatori Java full-stack senior, 1 lider tehnic, 2 Java intermediari dezvoltatori.</p>
                                        <p>Tehnologiile utilizate:</p>
                                        <ul>
                                            <li>Java</li>
                                            <li>Tomcat</li>
                                            <li>Oracle</li>
                                            <li>Javascript</li>
                                            <li>DHTMLX</li>
                                            <li>XSLT</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h3>Rezultate</h3>
                            <p>La finalizarea proiectului, am verificat și testat împreună cu partenerii noștri
                                 funcționalitățile sistemului și ne-am asigurat că totul funcționează în parametrii
                                 indicați de aceștia și că eDoc-ul realizat de Vertical Digital s-a
                                 dovedit a fi de mare ajutor în viața de zi cu zi a profesioniștilor companiei aeriene,
                                 care au la un click distanță documentele de care au nevoie pentru ca totul să funcționeze
                                 armonios.</p>
                            <h3>Opinii</h3>
                            <p>”Lucrand la acest proiect, am simțit că fac zi de zi un progres semnificativ în cariera
                                 mea de programator. Această experiență este o oportunitate de a-mi îmbunătăți cunoștințele,
                                 cât și o provocare pentru mine. Am învățat mult despre SQL și am progresat în dezvoltare
                                 folosind Java, JavaScript, TypeScript și dhtmlx 5,7,8., spune Adrian B., Vertical Digital</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CaseStudiesDetailsContent