import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import CaseStudiesDetailsContent from '../../components/case-studies/companie-aeriana/CaseStudiesDetailsContent'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Companie aeriană"
                homePageText="Studii de caz"
                homePageUrl="/studii-de-caz"
                activePageText="Companie aeriană"
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}

export default CaseDetails