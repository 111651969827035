import React from 'react'

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Client:</span>
                        <p>Companie din domeniul aviației din Europa</p>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Locație:</span>
                        Germania
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Tehnologii:</span>
                        Java, Tomcat, Oracle, Javascript, DHTMLX, XSLT
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-calendar'></i>
                        </div>
                        <span>Durată contract:</span>
                        <p>4 ani (în derulare)</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CaseStudiesSidebar